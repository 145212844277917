import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.span`
  position: relative;
  top: 6px;
  user-select: none;

  .path {
    fill: rgba(255,255,255,.85);
  }

  &:hover .path {
    fill: rgba(255,255,255,.95);
  }
  
  &.active {
    filter: drop-shadow(0 1px 0 rgba(255, 255, 255, 0.12));
    .path {
      fill: rgba(0, 0, 0, 0.4);
    }
  }
`

const RootContainer: React.FC<{ active: boolean }> = ({ active }) => {
  return <Wrapper className={active ? 'active' : ''}>
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
      <path fill="none" d="M0 0h24v24H0z" />
      {active
        ? <path className="path" d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" /> 
        : <path className="path" d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
      }
    </svg>
  </Wrapper>
}

export default RootContainer