import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { linesRem } from '~/utils/styling'

const StyledFooter = styled.footer`
  background: #42196c;
  padding: ${linesRem(.5)} 5px;
  cursor: default;
  a, a:visited {
    color: rgba(255,255,255,.75);
  }
`

const Footer: React.FC = () => {
  const data = useStaticQuery(graphql`
    query metadataForFooter {
      site {
        siteMetadata {
          footerText
          author
        }
      }
    }
  `)

  return (
    <StyledFooter>
      <div className="fixed-width">
        <p>{data.site.siteMetadata.footerText}</p>
        <p><strong>© {data.site.siteMetadata.author}, 2019-{(new Date()).getFullYear()}</strong></p>
      </div>
    </StyledFooter>
  )
}

export default Footer