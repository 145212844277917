import React from 'react'
import styled from 'styled-components'

const StyledDiv = styled.div`
  /* display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100vh;
  align-items: stretch; */
  z-index: -50;
`

const RootContainer: React.FC = ({ children }) => {
  return <StyledDiv>{children}</StyledDiv>
}

export default RootContainer