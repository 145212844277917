import React from 'react'
import styled from 'styled-components'
import { useAppContext } from '~/contexts/appContext'
import { appBarHeight } from '~/utils/styling'
import { useSwipeable } from 'react-swipeable'

const StyledDiv = styled.div<{ drawer: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: #f1f1f1;
  flex: 1;
  z-index: 5;

  transform: ${ ({ drawer }) => drawer ? 'translateX(-250px)' : 'none'};
  /* @media (min-width: 560px) {
    transform: ${ ({ drawer }) => drawer ? 'translateX(-50vw)' : 'none'};
  } */
  /* box-shadow: 0 0 10px rgba(0,0,0,.7); */
  transition: transform .3s cubic-bezier(1,.3,.5,.94);

  &.blur {
    filter: blur(2px);
    overflow: hidden;
  }

  &.drawer{
    &:after {
      content: "";
      position: absolute;
      width: 20px;
      background: linear-gradient(90deg, rgba(0,0,0,.1) 0%, rgba(0,0,0,0) 30%);
      right: -20px;
      top: -${appBarHeight}px;
      bottom: 0;
    }
  }
`

const ContentView: React.FC = ({ children }) => {
  const app = useAppContext()
  const handlers = useSwipeable({
    onSwipedLeft: e => {
      app.dispatch({ type: `OPEN_DRAWER` })
    },
    onSwipedRight: e => {
      app.dispatch({ type: `CLOSE_DRAWER` })
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
    delta: 280,
    trackTouch: true,
  })
  return (
    <StyledDiv
      {...handlers}
      className={app.state.drawer ? 'drawer' : ''}
      drawer={app.state.drawer}
    >
      {children}
    </StyledDiv>
  )
}

export default ContentView